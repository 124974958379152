import React from "react";
import {
  Typography,
} from "@material-tailwind/react";
import ProjectSection from '../components/ProjectSection'
import LockLimitedSection from '../components/LockLimitedSection'
import StatSection from '../components/StatSection'
import ContactSection from '../components/ContactSection'
import AchievementSection from '../components/AchievementSection'
export default function LockLimited() {
  return (
    <main className="">
      <div className="container mx-auto">
        <section id='project' className="my-4">
          <Typography variant='h2' className="text-3xl text-center mb-6">Lock Limited</Typography>
        </section>
        <section id='contact' className="my-4">
          <LockLimitedSection />
        </section>
      </div>
    </main>
  );
}
