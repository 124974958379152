import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import FacebookTool from "./pages/FacebookTool";
import LockLimited from "./pages/LockLimited";
import Error from "./pages/Error";

import "./index.css";


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/lock-limited" element={<LockLimited />} />
        <Route path="/facebook-tool" element={<FacebookTool />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
