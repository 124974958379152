import React, { useState, useEffect } from 'react';
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
} from 'chart.js';

ChartJS.register(
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
)
const FacebookTool = () => {
  const [accounts, setAccounts] = useState([]);
  const [carCamLA, setCarCamLA] = useState([]);
  const [carCamLB, setCarCamLB] = useState([]);
  const [followersData, setFollowersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchFacebookData = async () => {
    try {
      //EAAIzGjgempEBOzixxEr4nJAlC1quD6WkinCO9LCHoN0RAS2lkFJdW935sbsK0ZAvtMErbLerr7Yj5HBzOAq9J7CecwNDLzcu3d7cAAh9rAFsDIymADl2t9qvdVOuiNsJ94ZBIGyXbaZAWU2tNLo5VEwp2ewKKFbc2VE5O5p76tWz3YLzhAZCZBKCqZAZAhbfdNYBs58xpWbiMZAwkgtW
      //for common token

      //EAAIzGjgempEBO4kfMORxsZBOXCATPXo6MrrR93ZC3Q6ghFSPKd8ZAFi7eKfjMPTWMdBHj97g9Ey4GE19fRvgf0pdWARQZCdPKx94fb0VzAnbjFS9q0jBTh5LVAym0ZA9dpoMFlqnxs2ZCInZCfXiAovrjGY6CgrQB6ZBFl8vjLw5yEfqIjQV0v9x45WZAaWd2oifxO1ZCZBehbH9F5ilEpPZBf5PveZCd
      //for page token

      const response = await fetch('https://graph.facebook.com/v21.0/me/accounts?access_token=EAAIzGjgempEBOzixxEr4nJAlC1quD6WkinCO9LCHoN0RAS2lkFJdW935sbsK0ZAvtMErbLerr7Yj5HBzOAq9J7CecwNDLzcu3d7cAAh9rAFsDIymADl2t9qvdVOuiNsJ94ZBIGyXbaZAWU2tNLo5VEwp2ewKKFbc2VE5O5p76tWz3YLzhAZCZBKCqZAZAhbfdNYBs58xpWbiMZAwkgtW');
      const data = await response.json();
      setAccounts(data.data);

      const responseCarCamLA = await fetch('https://graph.facebook.com/v21.0/492163827320027/feed?limit=5&access_token=EAAIzGjgempEBO4kfMORxsZBOXCATPXo6MrrR93ZC3Q6ghFSPKd8ZAFi7eKfjMPTWMdBHj97g9Ey4GE19fRvgf0pdWARQZCdPKx94fb0VzAnbjFS9q0jBTh5LVAym0ZA9dpoMFlqnxs2ZCInZCfXiAovrjGY6CgrQB6ZBFl8vjLw5yEfqIjQV0v9x45WZAaWd2oifxO1ZCZBehbH9F5ilEpPZBf5PveZCd');
      const dataCarCamLA = await responseCarCamLA.json();
      setCarCamLA(dataCarCamLA.data);

      const responseCarCamLB = await fetch('https://graph.facebook.com/v21.0/111565526989245/feed?limit=5&access_token=EAAIzGjgempEBO4kfMORxsZBOXCATPXo6MrrR93ZC3Q6ghFSPKd8ZAFi7eKfjMPTWMdBHj97g9Ey4GE19fRvgf0pdWARQZCdPKx94fb0VzAnbjFS9q0jBTh5LVAym0ZA9dpoMFlqnxs2ZCInZCfXiAovrjGY6CgrQB6ZBFl8vjLw5yEfqIjQV0v9x45WZAaWd2oifxO1ZCZBehbH9F5ilEpPZBf5PveZCd');
      const dataCarCamLB = await responseCarCamLB.json();
      setCarCamLB(dataCarCamLB.data);

      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFacebookData();
  }, []);

  const forecastNextDay = 1600 + (1600 - 1400);

  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "車cam L",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
      {
        label: "車cam L 專頁版",
        data: [33, 25, 35, 51, 54, 76],
        fill: false,
        borderColor: "#742774"
      }
    ]
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <main className="container mx-auto py-4">
      <section>
        <h2 className='font-bold text-lg italic'>Account Information:</h2>
        <div className='shadow-md p-4 mt-2'>
          <ul>
            {accounts.map(account => (
              <li key={account.id}>
                <strong>Name:</strong> {account.name} <br />
                <strong>Category:</strong> {account.category} <br />
                <strong>Tasks:</strong> {account.tasks.join(', ')}
                <br />
                <br />
              </li>

            ))}
          </ul>
        </div>
        <br />
        <p className='font-bold text-xl text-blue-900'>Your page information</p>
        <br />
        <div className='grid grid-cols-2'>
          <div>
            <h2 className='font-bold text-lg italic '>車Cam L 專頁版 Information:</h2>
            <div className='shadow-md p-4 mt-2'>
              <ul>
                {carCamLA.map(carA => (
                  <li key={carA.id}>
                    <strong>id:</strong> {carA.id} <br />
                    <strong>Created time:</strong> {carA.created_time} <br />
                    <strong>Message:</strong> {carA.message}
                  </li>
                ))}
              </ul>
            </div>
            <br />
          </div>
          <div>
            <h2 className='font-bold text-lg italic'>車cam L Information:</h2>
            <div className='shadow-md p-4 mt-2'>
              <ul>
                {carCamLB.map(carB => (
                  <li key={carB.id}>
                    <strong>id:</strong> {carB.id} <br />
                    <strong>Created time:</strong> {carB.created_time} <br />
                    <strong>Message:</strong> {carB.message}
                  </li>
                ))}
              </ul>
            </div>
          </div>

        </div>
      </section>
      <section>
        <p className='font-bold text-xl text-blue-900'>Your page forecast and statistics</p>
        <br />
        <div>
          <Line data={data} />
        </div>
        <h2 className='font-bold text-lg italic'>Forecast:</h2>
        <p>Based on linear regression, the forecasted number of followers for the next day is approximately {forecastNextDay}.</p>

        <h2 className='font-bold text-lg italic'>Conclusion:</h2>
        <p>By analyzing the data, we observe a steady growth in followers over the past few days. The forecast suggests a positive trend in follower count for the upcoming days.</p>
      </section>
    </main>
  );
};

export default FacebookTool;