import React, { useRef } from "react";
import { Button, Input, Textarea, Typography } from "@material-tailwind/react";
import { PhoneIcon, EnvelopeIcon, MapIcon } from "@heroicons/react/24/solid";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";


export function LockLimitedSection() {
  const form = useRef();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const sendToEmail = () => {
    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_GMAIL_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_EMAIL_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    ).then(
      () => {
        alert("Thank you for your enquiry! I will contact you soon.");
      },
      () => {
        alert("Sorry! The enquiry could not be submitted. Please contact +85267603968 for follow-up.");
      }
    );
  };

  const onSubmit = (data) => {
    sendToEmail();
    reset();
  };


  return (
    <section className="px-8 py-8 lg:py-16">
      <div className="container mx-auto text-center">
        <div className="grid grid-cols-1 gap-x-12 gap-y-6 lg:grid-cols-2 items-start">
          <div>
            <Typography variant="h2" className="text-left font-semibold text-2xl">
              Contact Info
            </Typography>
            <section className="mt-8 space-y-8">
              <a href="tel:+85293270022" className="flex">
                <PhoneIcon className="w-8 min-w-8" />
                <Typography variant="lead" className="text-left text-base my-auto ml-4">
                  +852 93270022
                </Typography>
              </a>
              <div className="flex">
                <MapIcon className="w-8 min-w-8" />
                <Typography variant="lead" className="text-left text-base my-auto ml-4">
                  Factory Unit A, G/F Wing Sum Factory Bldg No 2, No 12-14 Sam Chuk St, San Po Kong , Kln<br />
                  香港九龍新蒲崗三祝街12-14號榮森工業第二大廈地下A舖
                </Typography>
              </div>
            </section>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} ref={form} className="flex flex-col gap-4">
            <Typography variant="h2" className="text-left text-2xl">
              Get In Touch
            </Typography>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Typography variant="small" className="mb-2 text-left font-medium !text-gray-900">
                  First Name
                </Typography>
                <Input
                  {...register("first-name", {
                    required: "First Name is required",
                    pattern: {
                      value: /^[a-zA-Z\u4e00-\u9fa5 ]+$/,
                      message: "Only Chinese or English characters are allowed"
                    }
                  })}
                  color="gray"
                  size="lg"
                  placeholder="First Name"
                  label="First Name"
                  className="focus:border-t-gray-900"
                  containerProps={{
                    className: "!min-w-full",
                  }}

                />
                {errors['first-name'] && <Typography variant="small" className="text-left block mt-2 text-red-600 text-xs">{errors['first-name'].message}</Typography>}
              </div>
              <div>
                <Typography variant="small" className="mb-2 text-left font-medium !text-gray-900">
                  Last Name
                </Typography>
                <Input
                  {...register("last-name", {
                    required: "Last Name is required",
                    pattern: {
                      value: /^[a-zA-Z\u4e00-\u9fa5 ]+$/,
                      message: "Only Chinese or English characters are allowed"
                    }
                  })}
                  color="gray"
                  size="lg"
                  placeholder="Last Name"
                  label="Last Name"
                  containerProps={{
                    className: "!min-w-full",
                  }}
                />
                {errors['last-name'] && <Typography variant="small" className="text-left block mt-2 text-red-600 text-xs">{errors['last-name'].message}</Typography>}

              </div>
              <div className="col-span-2 md:col-span-1">
                <Typography variant="small" className="mb-2 text-left font-medium !text-gray-900">
                  Your Phone Number
                </Typography>
                <Input
                  {...register("phone-no", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^(\+?\d{1,15})$/,
                      message: "Invalid phone number"
                    }
                  })}
                  color="gray"
                  size="lg"
                  placeholder="12345678"
                  label="Phone Number"
                  className="focus:border-t-gray-900"
                  containerProps={{
                    className: "!min-w-full",
                  }}

                />
                {errors['phone-no'] && <Typography variant="small" className="text-left block mt-2 text-red-600 text-xs">{errors['phone-no'].message}</Typography>}
              </div>
              <div className="col-span-2 md:col-span-1">
                <Typography variant="small" className="mb-2 text-left font-medium !text-gray-900">
                  Your Email
                </Typography>
                <Input
                  {...register("email-address", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address"
                    }
                  })}
                  color="gray"
                  size="lg"
                  placeholder="name@email.com"
                  label="Email"
                  className="focus:border-t-gray-900"
                  containerProps={{
                    className: "!min-w-full",
                  }}

                />
                {errors['email-address'] && <Typography variant="small" className="text-left block mt-2 text-red-600 text-xs">{errors['email-address'].message}</Typography>}
              </div>

            </div>

            <div>
              <Typography variant="small" className="mb-2 text-left font-medium !text-gray-900">
                Your Message
              </Typography>
              <Textarea
                {...register("message", {
                  required: "Message is required",
                  maxLength: {
                    value: 200,
                    message: "Message cannot exceed 200 characters"
                  }
                })}
                rows={6}
                color="gray"
                label="Message"
                containerProps={{
                  className: "!min-w-full",
                }}
              />
              {errors['message'] && <Typography variant="small" className="text-left block mt-2 text-red-600 text-xs">{errors['message'].message}</Typography>}
            </div>
            <Button type="submit" className="w-full" color="gray">
              Send message
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default LockLimitedSection;